import { fromJS, Map } from "immutable";

import { PROJECT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";

const LOAD_CERTIFICATES_START =
  "app/projectCertificates/load_certificates_start";
const LOAD_CERTIFICATES_SUCCESS =
  "app/projectCertificates/load_certificates_success";
const LOAD_CERTIFICATES_FAILURE =
  "app/projectCertificates/load_certificates_failure";

const ADD_CERTIFICATE_START = "app/projectCertificate/add_certificate_start";
const ADD_CERTIFICATE_SUCCESS =
  "app/projectCertificate/add_certificate_success";
const ADD_CERTIFICATE_FAILURE =
  "app/projectCertificate/add_certificate_failure";
const ADD_CERTIFICATE_CANCEL = "app/projectCertificate/add_certificate_cancel";

const DELETE_CERTIFICATE_START =
  "app/projectCertificate/delete_certificate_start";
const DELETE_CERTIFICATE_SUCCESS =
  "app/projectCertificate/delete_certificate_success";
const DELETE_CERTIFICATE_FAILURE =
  "app/projectCertificate/delete_certificate_failure";

const EDIT_LINE = "app/projectCertificate/edit_line";

export const editLine = (index, isNew) => ({
  type: EDIT_LINE,
  payload: index,
  meta: { isNew }
});

export const cancelAddCertificate = () => ({ type: ADD_CERTIFICATE_CANCEL });

export const loadCertificates = (organizationDescriptionId, project) => {
  return async dispatch => {
    if (!project) {
      return false;
    }

    dispatch({ type: LOAD_CERTIFICATES_START });

    try {
      const projectCertificates = await project.getCertificates();
      dispatch({
        type: LOAD_CERTIFICATES_SUCCESS,
        payload: {
          projectCertificates
        },
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project[PROJECT_ID_FIELD]
        }
      });
    } catch (err) {
      logger(err, {
        action: "loadCertificates",
        organizationDescriptionId,
        project
      });
      dispatch({ type: LOAD_CERTIFICATES_FAILURE, error: true, payload: err });
    }
  };
};

export const addCertificate = (
  organizationDescriptionId,
  project,
  certificate,
  editedLine
) => {
  return async dispatch => {
    if (!project) {
      return false;
    }
    dispatch({ type: ADD_CERTIFICATE_START });

    try {
      const result = await project.addCertificate(
        certificate.certificate,
        certificate.key,
        certificate.chain
      );

      if ((result.data && result.data.code / 100) === 4) {
        return dispatch({
          type: ADD_CERTIFICATE_FAILURE,
          payload: result.detail,
          meta: { editedLine, isNew: true }
        });
      }

      const newCertificate = await result.getEntity();

      dispatch({
        type: ADD_CERTIFICATE_SUCCESS,
        payload: newCertificate,
        meta: {
          organizationDescriptionId,
          projectDescriptionId: project[PROJECT_ID_FIELD]
        }
      });
    } catch (err) {
      logger(err, {
        action: "addCertificate",
        organizationDescriptionId,
        project,
        certificate,
        editedLine
      });
      dispatch({
        type: ADD_CERTIFICATE_FAILURE,
        payload: err.detail || err,
        meta: { editedLine, isNew: true }
      });
    }
  };
};

export const deleteCertificate = (
  organizationDescriptionId,
  projectDescriptionId,
  certificate
) => {
  return async dispatch => {
    if (!certificate) {
      return false;
    }

    dispatch({ type: DELETE_CERTIFICATE_START });

    try {
      await certificate.delete();

      dispatch({
        type: DELETE_CERTIFICATE_SUCCESS,
        payload: certificate,
        meta: {
          organizationDescriptionId,
          projectDescriptionId
        }
      });
    } catch (err) {
      logger(err, {
        action: "deleteCertificate",
        organizationDescriptionId,
        projectDescriptionId,
        certificate
      });
      dispatch({ type: DELETE_CERTIFICATE_FAILURE, error: true, payload: err });
    }
  };
};

export default function projectCertificateReducer(state = new Map(), action) {
  switch (action.type) {
    case ADD_CERTIFICATE_START:
      return state.set("updateLoading", true);
    case LOAD_CERTIFICATES_START:
      return state.set("loading", true);
    case EDIT_LINE:
      return state
        .set("editedLine", action.payload)
        .set("isNew", action.meta.isNew);
    case ADD_CERTIFICATE_CANCEL:
      return state.set("editedLine", false).set("isNew", false);
    case ADD_CERTIFICATE_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .set("editedLine", false)
        .set("isNew", false)
        .set("updateLoading", false);
    case DELETE_CERTIFICATE_SUCCESS:
      return state.deleteIn([
        "data",
        action.meta.organizationDescriptionId,
        action.meta.projectDescriptionId,
        action.payload.id
      ]);
    case LOAD_CERTIFICATES_SUCCESS:
      return state.set("loading", false).set(
        "data",
        fromJS(
          action.payload.projectCertificates.reduce(
            (organizationsProjectsEnvironements, projectCertificate) => {
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ] = {};
              }
              if (
                !organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId]
              ) {
                organizationsProjectsEnvironements[
                  action.meta.organizationDescriptionId
                ][action.meta.projectDescriptionId] = {};
              }

              organizationsProjectsEnvironements[
                action.meta.organizationDescriptionId
              ][action.meta.projectDescriptionId][
                projectCertificate.id
              ] = projectCertificate;

              return organizationsProjectsEnvironements;
            },
            {}
          )
        )
      );
    case LOAD_CERTIFICATES_FAILURE:
      return state.set("loading", false).set("errors", action.payload);
    case ADD_CERTIFICATE_FAILURE:
      return state
        .set("loading", false)
        .set("errors", action.payload)
        .set("updateLoading", false);
    default:
      return state;
  }
}
